/*********************************************************
 * The vars.scss file is only for global constants.      *
 ***************************************************+++++*
 * For colors in type script files use the colors.ts     *
 * For colors in html files use the material-theme.scss. *
 * For styling use styles.scss                           *
 ***************************************************+++++*/

:root {
  --space-small: 15px;
  --space-medium: 25px;
  --space-large: 45px;
  --space-xlarge: 70px;
}
