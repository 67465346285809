/********************************************************
 * The styles.scss file is used for global styles.      *
 ********************************************************
 * For colors in type script files use the colors.ts    *
 * For colors in html file use the material-theme.scss. *
 * For global constants use ths vars.scss               *
 ********************************************************/

@use './fonts';
@use './vars';

::-webkit-scrollbar {
  width: var(--space-small);
}

::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

html,
body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: #fafafa;
  max-width: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.page {
  padding: var(--space-small);
  position: relative;
  display: block;
}

.container {
  justify-content: space-between;
  align-items: center;
  gap: var(--space-small);
  background-color: white;
}

.heading-tab {
  margin-left: var(--space-small);
  padding-top: var(--space-small);
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #f5f5f5;
    --mat-mdc-snack-bar-button-color: black;
    --mdc-snackbar-supporting-text-color: black;
    white-space: pre-wrap;
  }
}
.mat-mdc-dialog-actions {
  justify-content: space-between !important;
}

.mat-mdc-tab-header {
  background-color: white;
  margin-bottom: 1em;
}

.material-icons {
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

