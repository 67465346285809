/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/roboto-v30-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v30-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/roboto-v30-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/roboto-v30-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/roboto-v30-latin-italic.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/roboto-v30-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v30-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v30-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v30-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/roboto-v30-latin-italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/roboto-v30-latin-700.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/roboto-v30-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v30-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v30-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v30-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/roboto-v30-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/roboto-v30-latin-500.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/roboto-v30-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v30-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v30-latin-500.woff2")
      format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v30-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/roboto-v30-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/material-latin-400.woff2")
      format('woff2');
}





