/***********************************************************
 *  Angular Material Theme from pep.digital                *
 *  Tested with Angular v.17                               *
 *                                                         *
 * The material-theme.scss file is used for global colors. *
 ***********************************************************
 * For colors in type script files use the colors.ts       *
 * For global constants use the vars.scss.                 *
 * For styling use styles.scss                             *
 ***********************************************************/

@use "@angular/material" as mat;
@use "sass:map";
@use "./palette" as palette;

@include mat.core();

$typography: mat.define-typography-config(
  $font-family: "Roboto, sans-serif",
  $headline-1: mat.define-typography-level(36px, 32px, 700),
  $subtitle-1: mat.define-typography-level(32px, 32px, 700),
  $headline-2: mat.define-typography-level(28px, 32px, 700),
  $headline-3: mat.define-typography-level(24px, 32px, 700),
  $body-1: mat.define-typography-level(16px, 1.25, 400),
  $body-2: mat.define-typography-level(16px, 1.25, 400),
  $caption: mat.define-typography-level(16px, 1.25, 400),
  $button: mat.define-typography-level(16px, 1.25, 500),
);

$app-light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(palette.$primary-light-palette),
      accent: mat.define-palette(palette.$accent-light-palette),
      warn: mat.define-palette(palette.$warn-light-palette),
      grey: mat.define-palette(palette.$grey-light-palette),
    ),
    typography: $typography,
    density: 0,
  )
);

@include mat.all-component-themes($app-light-theme);

tfoot td {
  color: mat.get-contrast-color-from-palette(
    palette.$primary-light-palette,
    500
  ) !important;
  background-color: mat.get-color-from-palette(
    palette.$primary-light-palette,
    500
  ) !important;
}

$color-config: mat.get-color-config($app-light-theme);
$primary-palette: map.get($color-config, "primary");

.confirmed-wrapper {
  border: 2px solid mat.get-color-from-palette($primary-palette, 500);
}

.confirmed {
  color: mat.get-contrast-color-from-palette($primary-palette, 400);
  background-color: mat.get-color-from-palette($primary-palette, 500);
  padding: 5px var(--space-small);
  border-radius: 3px;
}
